<template>
  <v-app>
    <v-app-bar color="black"  app> 
       <v-img
 max-height="100"
  max-width="150"
  src="https://slstaticapp.z19.web.core.windows.net/loadinglogo.png"
></v-img>

      <v-toolbar-title class="headline text-uppercase">
      
        <span class="white--text font-weight-light">Internal Employee Self-Assessment</span>
      </v-toolbar-title>
     <!--<v-spacer></v-spacer>-->
    </v-app-bar>
    
<v-main>
  <v-container fluid>
    <v-card v-if="screen===1"
    
   class="mx-auto"
    max-width="786"
    >
    <!--<v-card-title class="d-flex justify-center">Internal Employee Self-Assessment</v-card-title>-->
    <p class="d-flex justify-center">{{timestamp}}</p>
   
    <v-form
    ref="form"
    v-model="valid"
    lazy-validation
    class="ma-5 pa-6"
    
  >
  
    <h4 class="text-justify">Please check the ALL boxes that apply to you for the statements below: </h4>
    
    <v-checkbox
      v-model="symptoms"
      class="pa-1"
      
    >
    <template v-slot:label>

  <h3 class="text-justify font-weight-black" style="color:black">
        Do you have any of the following new or worsening symptoms or signs? Symptoms should not be chronic or related to other known causes or conditions.
      </h3>
    </template>
    </v-checkbox>
      
     <v-list
      flat
      class="pl-2"
    >
    <v-list-item dense>
   * Fever or chills
    </v-list-item>
    <v-list-item
    dense>
      
        * Difficulty breathing or shortness of breath
      
    </v-list-item>
    <v-list-item dense>
    * Cough
    </v-list-item>
    <v-list-item dense>
     * Sore throat, trouble swallowing
    </v-list-item>
    <v-list-item dense>
    * No Runny nose/stuffy nose or nasal congestion
    </v-list-item>
    <v-list-item dense>
     * Decrease or loss of smell or taste
    </v-list-item>
      <v-list-item dense>
    * Nausea, vomiting, diarrhea, abdominal pain
    </v-list-item>
      <v-list-item dense>
    * Not feeling well, extreme tiredness, sore muscles
    </v-list-item>
     </v-list>
     <v-checkbox
      v-model="outsideCanada"
      class="pa-1"
    >
    <template v-slot:label>
     <h3 class="text-justify font-weight-black" style="color:black">
      Have you travelled outside of Canada in the past 14 days? 
     </h3>
    </template>
    </v-checkbox>
<v-checkbox
      v-model="contact"
      class="pa-1"
    >
    <template v-slot:label>
      <h3 class="text-justify font-weight-black" style="color:black">
       Have you had close contact with a confirmed or probable case of COVID-19?
      </h3>
    </template>
    </v-checkbox>
      <v-text-field
      v-model="email"
      :rules="emailRules"
      required
    >
     <template v-slot:label>
      <h3 class="text-justify font-weight-black" style="color:blue">
        Please enter your Email address here.
      </h3>
    </template>
    </v-text-field>
     <v-btn
      :disabled="!valid"
      color="success"
      class="mr-4 pl-4"
      @click="confirm"
    >
      Confirm
    </v-btn>
   

    </v-form>
  <div class="red lighten-4">
    <v-card-text >If you can non confirm the above you should not enter the office.</v-card-text>
    </div>
    </v-card>
    <v-card  v-if="screen===7"
 class="mx-auto red"
    max-width="786">
      <h4 class="text-justify red pa-5">
      Please do not enter the building and immediately contact your manager or Joe Pellerino, Health and Safety Manager at 416-300-9940. 
      </h4>
     
   
    </v-card>
     <v-card  v-if="screen===8"
 class="mx-auto "
 color="success"
    max-width="786"
   >
      <h3
    
       class="text-justify pa-5"
       >
      You are cleared for entrance to the work site. 
      </h3>
     
   
    </v-card>
  </v-container>
</v-main>

    <v-footer app>
    <!-- -->
  </v-footer>
  </v-app>
</template>
<script>
import axios from 'axios';
export default {
  data: () => ({
    valid: true,
      drawer: false,
      group: null,
      firstName:null,
      lastName:null,
      email:null,
      symptoms:false,
      outsideCanada:false,
      contact:false,
      timestamp:null,
      
        screen:null,
         nameRules: [
        v => !!v || 'Name is required',
       
      ],
        emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
       symptomsRules: [
        v => !!v || 'Answer is required'
        
      ],
       outsideRules: [
        v => !!v || 'Answer is required'
        
      ],
        contactRules: [
        v => !!v || 'Answer is required'
        
      ],
      
    }),
    created(){
      this.screen=1;
      this.getNow();
    },
    methods:{
    async  confirm () {
      
       var _validat= this.$refs.form.validate();
       if(_validat){
         if(this.symptoms){
this.screen=7;
         }else if(this.outsideCanada){
           this.screen=7;
         }else if(this.contact){
this.screen=7;
         }else{
         this.screen=8;
         }
         const headers = { 
    "x-functions-key":
          "za4UoZG16idX23920oJvRMjJLxmK1c0AIPfZNLn5YaBtDdPRF6Oecw=="
     
  };
var data={email:this.email,createAt:this.timestamp,symptoms:this.symptoms,outsideCanada:this.outsideCanada,contact:this.contact};
 console.log(data);
 await axios.post("https://slactions.azurewebsites.net/api/SaveCovidLog", data,{headers});
       }
       
      },
      validate1 () {
      
       var _validat1= this.$refs.form1.validate();
       if(_validat1){
         this.screen=3;
       }
      },
       validate2 () {
      
       var _validat2= this.$refs.form2.validate();
       if(_validat2){
         this.screen=4;
       }
      },
      onChange(v){
        if(v==="yes"){
          this.screen=7;
        }
     
      },
      onChange1(v){
        if(v==="yes"){
          this.screen=7;
        }
  
      },
      onChange2(v){
        if(v==="yes"){
          this.screen=7;
        }
      
      },
      submit(){
this.screen=8;
      },
      getNow(){
         const today = new Date();
                    const date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
                    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
                    const dateTime = date +' '+ time;
                    this.timestamp = dateTime;
      }
    }
 
   
   
}

</script>
